#static {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('./Assets/Images/static.gif') repeat center center fixed;
    opacity: .06;
    z-index: 99;
    pointer-events: none
}

#scan {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('./Assets/Images/scan.gif') repeat center center fixed;
    opacity: .06;
    z-index: 99;
    pointer-events: none
}

