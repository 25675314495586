.order-page {
    background-color: black;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.order-title {
    text-decoration: none;
    color: white;
}

.order-form-container {
    width: 80%;
    display: flex;
    flex-flow: column nowrap;
}