.portfolio-page {
    background-color: black;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.portfolio-title {
    text-decoration: none;
    color: white;
}

.portfolio-row {
    height: 260px;
    width: 100%;
   
    border-bottom: solid 10px black;
    display: flex;
    justify-content: left;
    
}

.portfolio-item {
    color: black;
    background-color: white;
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px black;
    text-decoration: none;
    font-size: 30px;
}

.portfolio-item-empty {
    background-color: black;
    width: 34%;
}