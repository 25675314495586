.about-page {
    background-color: black;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.about-page a {
    text-decoration: none;
    color: white;
}

.company-info-container {
    width: 100%;
    background-color: white;
    color: black;
    height: 260px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.company-info-container h2 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.company-info-container p {
    margin-top: -30px;
    margin-bottom: 0px;
}
.company-info-container a {
    color: black;
}

.about-spacer {
    height: 100px;
}

.about-nav {
    height: 240px;
    background-color: white;
    color: black;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.about-nav a {
    border: solid 1px black;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    text-decoration: none;
    color: black;
}