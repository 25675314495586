.launch-page {
    position: relative; /* Allows layering of child elements */
    height: 100vh;
    width: 100vw;
    overflow: hidden; /* Prevent scrolling */
    background-color: black; /* Fallback background color */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mandelbrot-plot {
    position: absolute; /* Center the Mandelbrot canvas in the page */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Fully center the canvas */
    z-index: 0; /* Ensure the canvas is in the background */
    pointer-events: none; /* Allow clicks to pass through the canvas */
}

.launch-title {
    color: black;
    text-decoration: none;
    z-index: 10; /* Ensure it’s above the Mandelbrot plot */
    position: relative;
    font-size: 1.4rem;
    margin-bottom: 0; /* Space between the title and logo */
    text-align: center;
}

.launch-logo-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    z-index: 10; /* Ensure it’s above the Mandelbrot plot */
    position: relative;
    text-align: center;
}

.launch-logo-link div {
    font-size: 26px;
    margin-top: 10px;
}

.logo {
    border-radius: 100px;
}
